.Cctv {
  .NumberField {
    align-self: stretch;
  }

  .cabling-section  .body {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
  }

  .recorder-section .body,
  .rsmodule-section .body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
  }

  .harddrive-section .body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
  }

  .camera-section .body,
  .installation-section .body,
  .storage-section .body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1em;
  }

  @media (width <=1000px) {
    .camera-section .body,
    .recorder-section .body,
    .rsmodule-section .body,
    .installation-section .body,
    .harddrive-section .body,
    .storage-section .body {
      grid-template-columns: 1fr;
    }
  }
}
