.start-gallery.image-gallery,
.start-gallery .image-gallery-content,
.start-gallery .image-gallery-slide-wrapper,
.start-gallery .image-gallery-swipe,
.start-gallery .image-gallery-slides,
.start-gallery .image-gallery-slide
{
  height: 100%;
  width: 100%;
}

.start-gallery.image-gallery {
  background: var(--primary-color-100);
}

.start-gallery .start-gallery-item {
  background:  linear-gradient(150deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0.75) 45%, rgba(0, 0, 0, 0.4) 100%),
   var(--img) center / cover no-repeat;
   line-height: initial;
   white-space: initial;
   text-align: start;
   justify-content: start;
   align-items: start;
   display: flex;
   flex-direction: column;
   min-height: 100%;
}

.start-gallery .image-gallery-bullets .image-gallery-bullet {
  padding: 8px;
  margin: 8px;
  border-width: 2px;
}

.start-gallery .image-gallery-bullets .image-gallery-bullet:hover {
  border-width: 2px;
}

.start-gallery .image-gallery-bullets {
  bottom: 150px;
}