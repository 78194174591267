.ConfigItem {
  align-items: center;
  border: 1px solid var(--primary-color-0);
  padding: 1em;
  cursor: pointer;
  
  img {
    width: 8em;
  }

  > *,
  .header > img {
    margin-top: 1em;
  }

  .header {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}