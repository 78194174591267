.NumberField {
  display: flex;

  >:nth-child(1) {
    order: 2;
    width: 50px;
    flex-grow: 1;
  }

  >:nth-child(2) {
    order: 1;
    width: 2em;
  }

  >:nth-child(3) {
    order: 3;
    width: 2em;
  }
}
