.Contact {
  form {
    margin-top: 2em;
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5em;
    font-size: 1.25em;
  }

  .span-2 {
    grid-column: 1 / span 2;
  }

  .container-1 {
    display: flex;
  }

  .container-2 {
    display: flex;
    flex-direction: column;
    margin-right: 2em;
  }

  .leaflet-container {
    flex-grow: 1;
  }

  @media (width <=1000px) {
    form {
      grid-template-columns: 1fr;
    }

    .span-2 {
      grid-column: 1;
    }

    .container-1 {
      flex-direction: column;
    }

    .container-2 {
      margin-right: 0;
      margin-bottom: 2em;
    }

    .leaflet-container {
      min-height: 200px;
    }
  }
}