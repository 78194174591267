.logo {
  display: grid;
  grid-template-columns: 1fr 1fr 10fr;
  gap: calc(var(--logo-height) / 8);
  place-items: center center;
  height: var(--logo-height);

  --border-width: 2px;
}

.logo-text {
  font-family: BankGothicMedium;
  color: var(--tsn-green);
  grid-column: 3;
  grid-row: 1 / span 2;
  font-size: var(--logo-height);
  line-height: 1;

}

.logo>.square {
  aspect-ratio: 1/1;
  height: calc(var(--logo-height) / 4);
}

.top-right {
  grid-column: 2;
  grid-row: 1;
  border: var(--border-width) solid var(--tsn-orange);
  align-self: end;
}

.bottom-left {
  grid-column: 1;
  grid-row: 2;
  border: var(--border-width) solid var(--tsn-green);
  align-self: start;
}

.bottom-right {
  grid-column: 2;
  grid-row: 2;
  background-color: var(--tsn-orange);
  border: var(--border-width) solid transparent;
  align-self: start;
}