@import './form.css';
@import './header.css';

html {
  --tsn-green-rgb: 33, 168, 4;
  --tsn-green: rgb(var(--tsn-green-rgb));
  --tsn-green-dark-rgb: 22, 116, 3;
  --tsn-green-dark: rgb(var(--tsn-green-dark-rgb));
  --tsn-orange: #f17e01;
  --tsn-orange-dark: #c96c01;

  --primary-color-0: #eaebec;
  --primary-color-10: #d6d7d8;
  --primary-color-20: #d6d7d8;
  --primary-color-30: #c1c2c5;
  --primary-color-40: #adaeb1;
  --primary-color-50: #989a9e;
  --primary-color-60: #83868b;
  --primary-color-70: #6f7277;
  --primary-color-80: #5a5d64;
  --primary-color-90: #464950;
  --primary-color-100: #31353d;
  --primary-color-110: #2c3037;
  --primary-color-120: #272a31;
  --primary-color-130: #22252b;
  --primary-color-140: #1d2025;
  --primary-color-150: #191b1f;
  --primary-color-160: #141518;
  --primary-color-170: #0f1012;
  --primary-color-180: #0a0b0c;
  --primary-color-190: #050506;

  font-family: "Source Sans 3", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px;
}


@font-face {
  font-family: BankGothicMedium;
  src: url("./assets/Bank.ttf") format("truetype");
}

body {
  margin: 0;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

.text {
  font-size: 30px;
  font-weight: 300;
  line-height: 1.28;
}

main {
  flex-grow: 1;
  display: flex;
  overflow: auto;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: max(5vh, 100px);
  padding-left: max(10vw, 80px);
  padding-right: max(10vw, 80px);
  padding-bottom: 2em;

  flex-grow: 1;
  width: 100%;
  background: linear-gradient(150deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0.75) 65%, rgba(0, 0, 0, 0.4) 100%),
    url(./assets/business-bg.webp) center / cover no-repeat;
}

.text-bg {
  background: var(--primary-color-100);
  padding: 2em;
  width: min(100%, 1280px);
  flex-grow: 1;
}

@media (width <=1000px) {
  .main-content {
    padding: 1em;
    justify-content: center;
    align-items: center;
  }

  .text-bg {
    margin: 0;
  }

  .text {
    font-size: initial;
  }
}

.glow {
  box-shadow: 0px 0px 6px 1px var(--primary-color-0);
}