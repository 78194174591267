label {
  display: inline-flex;
  flex-direction: column-reverse;
}

label > [required] + span:after {
  display: inline;
  content: "*";
  margin-left: 0.5ex;
}

input:not([type="radio"], [type="checkbox"]), textarea, select {
  outline: none;
  padding: 1em 0.75em;
  border: 1px solid var(--primary-color-30);
  color: var(--primary-color-150);
}

input:user-invalid,
textarea:user-invalid,
select:user-invalid {
  background: rgb(248, 163, 163);
}

button {
  border-radius: 0;
  border: none;
  color: var(--primary-color-0);
  background: var(--primary-color-150);
  font-size: 1.25em;
  padding: 0.5em;
  cursor: pointer;

  background-position: center;
  transition: background 0.8s, color 0.8s;
}
button:hover {
  background: var(--primary-color-80) radial-gradient(circle, transparent 1%, var(--primary-color-80) 1%) center/15000%;
  color: white;
}
button:active {
  background-color: var(--primary-color-150);
  background-size: 100%;
  transition: background 0s, color 0s;
}

input[type="radio"] {
  background: var(--primary-color-0);
  display: inline-grid;
  place-content: center;

  -webkit-appearance: none;
  appearance: none;

  font: inherit;
  color: currentColor;
  width: 1.75em;
  height: 1.75em;
  border: 0.15em solid currentColor;
}
input[type="radio"]::before {
  content: "";
  width: 1.25em;
  height: 1.25em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1.25em 1.25em var(--primary-color-100);
}
input[type="radio"]:checked::before {
  transform: scale(1);
}
input[type="radio"]:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
}
input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.75em;
  height: 1.75em;
  border: 0.15em solid currentColor;
  border-radius: 0;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
input[type="checkbox"]::before {
  content: "";
  width: 1.25em;
  height: 1.25em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background: url("./assets/check-mark.svg") center center / cover no-repeat;
}
input[type="checkbox"]:checked::before {
  transform: scale(1);
}