.Accordion {
  border: 1px solid var(--primary-color-0);
  overflow: hidden;
  
  >.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    border-bottom: 1px solid var(--primary-color-0);
    position: relative;
    z-index: 1;
    background: var(--primary-color-100);
    
    >.title {
      margin: 0;
      flex-grow: 1;
      cursor: pointer;
    }

    >.control {
      transform: rotate(180deg);
      background: url("../../../../assets/chevron-down.svg") no-repeat center center / cover;
      min-width: 2em;
      min-height: 2em;
      transition: transform 0.25s ease;
      cursor: pointer;
      border: 2px solid var(--primary-color-0);
      border-radius: 50%;
    }
  }


  >.body {
    overflow: hidden;
    padding: 1em;
  }

}

.Accordion[data-open="false"] {

  >.body {
    padding: 0;
    height: 0;
  }

  >.header {
    border-bottom: none;

    >.control {
      transform: rotate(0deg);
    }
  }
}