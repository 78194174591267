h3 {
  font-size: calc(4 * 1rem - 4px);
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 1em;
  margin-top: 0;
}

@media (width <=1000px) {
  h3 {
    font-size: calc(2 * 1rem - 2px);
  }
}

h4 {
  font-size: calc(2 * 1rem - 2px);
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 1em;
  margin-top: 0;
}

@media (width <=1000px) {
  h4 {
    font-size: calc(1.25 * 1rem - 1.25px);
  }
}